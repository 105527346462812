import http from '@/http/token-http';
import APIFileService from '@/service/APIFileService';

class GameService extends APIFileService {
  constructor() {
    super('/games');
    this.files = ['image'];
  }

  // eslint-disable-next-line class-methods-use-this
  buildFormData(game) {
    const formData = new FormData();
    formData.append('name', game.name);
    formData.append('image_path', game.image_path);
    formData.append('image', game.image);
    return formData;
  }

  static addPlayer(object) {
    if (object.players.length === 0) {
      object.players.push({});
    }
    return object;
  }

  async indexWithPlayer(userId) {
    return http.get(`${this.resource}/-/users/${userId}`)
      .then((res) => res.data)
      .then((games) => games.map((game) => GameService.addPlayer(game)));
  }
}

export default new GameService();
